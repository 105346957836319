import React from 'react'
import ReactTable from 'react-table-v6'
import axios from 'axios';
import 'react-table-v6/react-table.css'
import Pagination from './pagination'


class CBProvider {

  static CB_API_URI = `${process.env.GATSBY_ESA_API_PROTOCOL}://${process.env.GATSBY_ESA_API_BASEURL}/esta/v1/certifyingbodies/`

  static certifyingBodies(pageSize, page, sorted, filtered) {
    
    let params = {
      limit: pageSize,
      offset: page > 0?pageSize*page:0,
      ordering: sorted
    }
    return axios.get(this.CB_API_URI, {params})
  }
}

const tableColumns = [
  {
    Header: 'Name',
    id: 'name',
    accessor: 'name',
    minWidth: 175,
    sortable: true
  },
  {
    Header: 'Address',
    id: 'address',
    accessor: d =>  `${d.address.street?d.address.street:''} ${d.address.street_number?d.address.street_number:''} ${d.address.zip_code?d.address.zip_code:''} ${d.address.city}`,
    sortable: false
  },
  {
    Header: 'Country',
    id: 'country',
    accessor : d => d.country.name,
    sortable: true
  },
  {
    Header: 'Accreditation Number',
    id: 'accreditation_number',
    accessor: 'accreditation_number',
    sortable: true
  },
  {
    Header: 'Date of acceptation',
    id: 'acceptation_date',
    accessor: d => d.acceptation_date,
    sortable: true,
  },
  /*{
    Header: 'ESTA registration number',
    id: 'registration_number',
    accessor: 'registration_number',
    sortable: true,
  },*/
  {
    Header: 'Scope',
    id: 'scope',
    accessor: 'scope',
    sortable: false,
  },
  {
    Header: 'Remarks',
    id: 'remarks',
    accessor: 'remarks',
    sortable: false,
  }
].map(e=>{return {...e, minWidth:150}})

export class CBTable extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      
      pages: 1,
      data: [],
      loading: true,
      count: 0
    
    }
    this.fetchData = this.fetchData.bind(this)
  }
  fetchData({page, pageSize, sorted, filtered}){
    if(!!sorted && sorted.length > 0){
      sorted = `${sorted[0].desc?'-':''}${sorted[0].id}`
    }
    
    this.setState({loading:true})
    CBProvider.certifyingBodies(
      pageSize,
      page,
      sorted,
      filtered
    ).then(({data})=>{
      this.setState({
        data: data.results,
        pages: Math.ceil(data.count/pageSize),
        loading:false,
        count: data.count
      })
    })
      .catch(({data})=>{
        this.setState({
          loading:false,
          pages: 0,
          data:[],
          count: 0
        })
      })
  }

  render(){
    return (
      <div className="table-container">
        <ReactTable
          data = { this.state.data }
          columns = {tableColumns}
          loading = {this.state.loading}
          className = 'main-table'
          resizable={false}
          minRows={0}
          defaultPageSize={10}
          pages = {this.state.pages}
          PaginationComponent={props => <Pagination {...props}/>}
          defaultSorted={[
            {
              id:"name",
              desc: false
            }

          ]}

          noDataComponent = {()=>(<p className="tableNoResults">No Results</p>)}
          onFetchData={this.fetchData}
          manual
        />
      </div>
    )
  }
}
