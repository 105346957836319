import React, {useState} from "react"
import {navigate} from 'gatsby'
import {resetPassword} from './auth.js'
import {withFormik} from 'formik'
import {InputField, SubmitButton} from 'components/gravityforms/fields'
import {ReadMoreButton} from 'components/buttons'

const ResetPasswordForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit
  } = props
  return (
   
    <form onSubmit={handleSubmit}>
      {errors.form_error &&
       <div className="formfield__error text-red mt-2">{errors.form_error}</div>
      }
      <InputField
        label="Email"
        name="email"
        type="email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        touched={!!touched.email}
        required={true}
      />

      <div className="text-center">
        <SubmitButton className="mt-6 uppercase py-2 px-16 text-center" label="Submit"/>
      </div>
    </form>
  )
}

const ResetPasswordFormComponent = withFormik({
  mapPropsToValues: () => ({
    email: '',
  }),
  validate: values => {
    const errors = {}
    if(!values.email){
      errors.username = 'Email address is Required'
    }
    return errors;
  },
  handleSubmit: async (values,{setSubmitting, setErrors, props}) => {
    try{
      await  resetPassword(values.email, props.redirectPath)
      setSubmitting(false)
      props.onSubmitSuccess()
    }catch (err){
      if(!!err.response && !!err.response.data){
        setErrors({form_error: err.response.data.non_field_errors[0]})
      }else {
        console.err(err);
        setErrors({form_error: "Something unexpected happened, please try again later"})
      }

      setSubmitting(false)
    }
    
  },
  displayName: 'LoginForm'

})(ResetPasswordForm);
export default  ({redirectTo, ...rest}) => {
  let [state,setState] = useState(false);
  let confirmPath = redirectTo;
  if(!!rest.location && !!rest.location.origin ){
    confirmPath = `${rest.location.origin}${redirectTo}`
  }
  return (
    <div className="entry-content" >
      <h1 className="text-center mb-16">Forgot your password?</h1>
      <div style={{maxWidth:480, margin:"0 auto"}}>
        {!state &&
         <>
        <div className="mb-8">
          <p>Enter your email address below to reset your password</p>
        </div>
        
        <ResetPasswordFormComponent
          redirectPath={confirmPath}
          
          onSubmitSuccess={()=>setState({})}
        
        />
 
         </>
        }
        {state &&
         <div className="mb-8">
           <p className="text-center">Your will receive a link to change your password by email if your email address exists in our system</p>
         </div>
        }
        <div className="flex flex-no-wrap align-center justify-center py-5">
          <p className="text-sm tracking-tight " style={{marginTop:2}}>Already have an account?</p>
          <ReadMoreButton
            text="Sign In"
            link={{title: "Sign In", url: rest.loginPath}}
            fontColor="black"
            showSymbol={true}
            className="mx-5"
          ></ReadMoreButton>
        </div>
      </div>
    </div>
  )
}
