import axios from 'axios'

export const isBrowser = typeof window !== `undefined`


const AUTH_ENDPOINT =  `${process.env.GATSBY_ESA_API_PROTOCOL}://${process.env.GATSBY_ESA_API_BASEURL}/rest-auth`
const getUser = () =>
      window.localStorage.pintoUser
      ? JSON.parse(window.localStorage.pintoUser)
      : {}

const  getToken = () => {
  const user = getUser();
  if(!!user.token){
    return user.token
  }
  return false;
}
  

const setUser = user => window.localStorage.pintoUser = JSON.stringify(user)

const client = () => {
    let headers = {}
    const token = getToken()
    if(!!token){
      headers={
        ...headers,
        Authorization: `Token ${token}`
      }
    }
    return axios.create({
      baseURL: AUTH_ENDPOINT,
      headers
    })
  }


export const getProfile = async () => {
    const token = getToken()
    try{
      let response = await client()
          .get(`/user/`)
      setUser({...response.data, token:token})
      return response.data
    }catch (err){
      console.error(err.response)
      return false
    }
}
export const login = async (username, password) => {
  if(!isBrowser) return false
  try{
    const {data} = await client().post(`/login/`, {
      username,
      password
    });
    
    setUser({token:data.key})
    await getProfile()
  }catch (err){
    throw err
  }
}

export const register = async(user) => {
  if(!isBrowser) return false
  try {
    const {data} = await client().post('/registration/', {
      ...user
    })
    setUser({token: data.key})
    await getProfile()
  }catch(err){
    throw err
  }
}
export const resetPassword = async(email, confirmUrl) => {
  if(!isBrowser) return false
  try {
    const {data} = await client().post('/password/reset/', {
      email: email,
      confirmUrl: confirmUrl
    })
  }catch(err){
    throw err
  }
}
export const changePassword = async(password, password2, uid, token) => {
  if(!isBrowser) return false
  try {
    const {data} = await client().post(`/password/reset/confirm/`, {
      uid: uid,
      token: token,
      new_password1: password,
      new_password2: password2
    })
  }catch(err){
    throw err
  }
}
export const logout = async (callback) => {
  if(!isBrowser) return
  await client().post('/logout')
  setUser()
  callback()
}

export const isLoggedIn = () => {
  if (!isBrowser) return false
  const user = getUser()
  return !!user.email
}


