import React, {useState, useEffect} from "react"
import axios from 'axios'
import {navigate} from 'gatsby'
import {login, register, isLoggedIn} from './auth.js'
import {withFormik} from 'formik'
import {InputField, SubmitButton, SelectInput} from '../../gravityforms/fields'
import {CheckBox} from 'components/gravityforms/fields/checkbox'
import {ReadMoreButton} from 'components/buttons'
import Styles from './register.module.css'

const RegisterForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    countries
  } = props


  return (
    <form onSubmit={handleSubmit}>
      {errors.form_error &&
       <div className="formfield__error text-red mt-2">{errors.form_error}</div>
      }
      <div className="md:flex justify-between">
        <div className={Styles.fieldGroup}>
           <InputField
        label="First Name"
        name="first_name"
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.first_name}
        error={errors.first_name}
        touched={!!touched.first_name}
        isRequired={true}
      />
      <InputField
        label="Last Name"
        name="last_name"
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.last_name}
        error={errors.last_name}
        touched={!!touched.last_name}
        isRequired={true}
      />
      <InputField
        label="Company Name"
        name="company_name"
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.company_name}
        error={errors.company_name}
        touched={!!touched.company_name}
        isRequired={true}
      />
      <SelectInput
        label="Country"
        name="country"
        isRequired={true}
        choices={
          countries
        }
        onChange={(option) => { props.setFieldValue('country', option)}}
        onBlur={handleBlur}
        value={values.country}
        error={errors.country}
        touched={!!touched.country}
      />
        </div>
        <div className={Styles.fieldGroup}>

          <InputField
        label="Username"
        name="username"
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.username}
        error={errors.username}
        touched={!!touched.username}
        isRequired={true}
      />
      <InputField
        label="E-mail"
        name="email"
        type="email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        touched={!!touched.email}
        isRequired={true}
      />
      <InputField
        label="Password"
        name="password1"
        type="password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password1}
        error={errors.password1}
        touched={!!touched.password1}
        isRequired={true}
      />
      <InputField
        label="Confirm Password"
        name="password2"
        type="password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password2}
        error={errors.password2}
        touched={!!touched.password2}
        isRequired={true}
      />

        </div>
      </div>
      <div>

        <CheckBox
          checkboxLabel="<strong>I agree</strong> to the terms of use"
          value={values.accept_terms}
          onChange={handleChange}
          onBlur={handleBlur}
          name="accept_terms"
          isRequired={true}
          error={errors.accept_terms}
          touched={!!touched.accept_terms}
        />
        <CheckBox
          checkboxLabel="I have read and accepted the PINTO Privacy Policy and I give my consent to the use of my personal information in line with that Privacy Policy"
          value={values.accept_privacy_policy}
          onChange={handleChange}
          onBlur={handleBlur}
          name="accept_privacy_policy"
          touched={!!touched.accept_privacy_policy}
          error={errors.accept_privacy_policy}
          isRequired={true}
        />
        <div className="text-center">
          <SubmitButton className="mt-6 uppercase py-2 px-16 text-center" label="Register"/>
          <div className="flex flex-no-wrap align-center justify-center py-5">
            <p className="text-sm tracking-tight " style={{marginTop:2}}>Already have an account?</p>
            <ReadMoreButton
              text="Sign In"
              link={{title: "Sign In", url: props.loginPath}}
              fontColor="black"
              showSymbol={true}
              className="mx-5"
            ></ReadMoreButton>
          </div>
        </div>

      </div>


    </form>
  )
}

const RegisterFormComponent = withFormik({
  mapPropsToValues: () => ({
    first_name: '',
    last_name: '',
    company_name: '',
    country: '',
    username: '',
    email: '',
    password1: '',
    password2: '',
    accept_terms: false,
    accept_privacy_policy: false

  }),
  validate: values => {
    const errors = {}
    if(!values.first_name){
      errors.first_name = 'First name is Required'
    }
    if(!values.last_name){
      errors.last_name = 'Last name is Required'
    }
    if(!values.country){
      errors.country = "You need to choose a country"
    }
    if(!values.username){
      errors.username = 'Username is Required'
    }
    if(!values.email){
      errors.email = 'Email is Required'
    }else {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      if (!reg.test(values.email))
      {
        errors.email = 'Invalid e-mail address'
      }
    }
    if(!values.password1){
      errors.password1 = 'Password is Required'
    }
    if(!values.password2){
      errors.password2 = 'Password confirmation is required'
    }
    else if (values.password1 !== values.password2){
      errors.password1 = "Password and password confirmation doesn't match"
      errors.password2 = "Password and password confirmation doesn't match"
    }
    if(!values.accept_privacy_policy){
      errors.accept_privacy_policy = "You must agree to the privacy policy"
    }
    if(!values.accept_terms){
      errors.accept_terms = "You must agree to the terms of use"
    }
    if(!values.company_name){
      errors.company_name="Company Name is required"
    }

    return errors;
  },
  handleSubmit: async (values,{setSubmitting, setErrors, props}) => {
    let processed_values = {
      ...values,
      country_id: values.country.value
    }

    delete processed_values.country;
    try{
      await  register(processed_values)
      setSubmitting(false)
      props.onSubmitSuccess()
    }catch (err){
      console.error(err)
      setErrors(err.response.data)
      setSubmitting(false)
    }

  },
  displayName: 'RegisterForm'

})(RegisterForm);


export default  ({redirectTo, ...rest}) => {
  let [state,setState] = useState();
  let [showConfirmation, setConfirmation] = useState(false);
  let [countries, setCountries] = useState([])
  useEffect(()=>{
    const fetchData = async() => {
      const {data} = await axios.get(`${process.env.GATSBY_ESA_API_PROTOCOL}://${process.env.GATSBY_ESA_API_BASEURL}/common/v1/countries/`, {params:{limit:500}})

      const res = data?.results?data.results:[];

      setCountries(data?.results?.map(c=>{return {value: c.id, text:c.name}}))

    }
    fetchData()
  }, [])

  if(isLoggedIn()) {
    setTimeout(()=>{
      navigate(`${redirectTo}`, {replace:true})

    }, 3000);
  }
  return (
    <div className='entry-content' style={{maxWidth:640, margin:"0 auto"}}>
      <h1 className="text-center mb-16">Register</h1>
      <div className={Styles.formContainer}>
        { !showConfirmation?
        <RegisterFormComponent
          redirectPath={redirectTo}
          onSubmitSuccess={()=>setConfirmation(true)}
          countries={countries}
          loginPath={rest.loginPath}
          />
          :<p className="text-center">
             Your registration was successfull. you will be redirected shortly.
           </p>
        }
      </div>
    </div>
  )
}
