import React from 'react'
import Styles from './style.module.css'
import {ReactComponent as PetalIcon} from 'images/icons/seeds-point.svg'

const Pagination = ({actual, total, onChange}) => {
  if(total < 10) {
    return (
      <div className={Styles.container}>
        <button className={`${Styles.button} ${Styles.arrow}`} onClick={onChange.bind(this, ((actual - 1) + total) % total )}>&lt;</button>
        {Array.from({ length: total }).map((_, index)=> (
          <button 
            className={index == actual ? `${Styles.button} ${Styles.active}` : Styles.button}
            onClick={onChange.bind(this, index)}
          >
            {index+1}
            {index == actual && 
              <div className={Styles.petal}>
                <PetalIcon />
              </div>
            }
          </button>
        ))}
        <button className={`${Styles.button} ${Styles.arrow}`} onClick={onChange.bind(this, (actual + 1) % total )}>&gt;</button>
      </div>
    )
  } else {
    let buttons = ''
    if(actual == 0){
      buttons = (
        <React.Fragment>
          <button className={`${Styles.button} ${Styles.active}`} onClick={onChange.bind(this, 0)}>
            1
            <div className={Styles.petal}>
              <PetalIcon />
            </div>
          </button>
          <button className={Styles.button} onClick={onChange.bind(this, 1)}>
            2
          </button>
          <button className={Styles.button} onClick={onChange.bind(this, 2)}>
            3
          </button>
          <div>...</div>
        </React.Fragment>
      )
    } else if(actual === total-1) {
      buttons = (
        <React.Fragment>
          <div>...</div>
          <button className={Styles.button} onClick={onChange.bind(this, total-3)}>
            {total-2}
          </button>
          <button className={Styles.button} onClick={onChange.bind(this, total-2)}>
            {total-1}
          </button>
          <button className={`${Styles.button} ${Styles.active}`} onClick={onChange.bind(this, actual)}>
            {total}
            <div className={Styles.petal}>
              <PetalIcon />
            </div>
          </button>
        </React.Fragment>
      )
    } else {
      buttons = (
        <React.Fragment>
          {actual >1 && <div>...</div>}
          <button className={Styles.button} onClick={onChange.bind(this, actual - 1)}>
            {actual}
          </button>
          <button className={`${Styles.button} ${Styles.active}`} onClick={onChange.bind(this, actual)}>
            {actual + 1}
            <div className={Styles.petal}>
              <PetalIcon />
            </div>
          </button>
          <button className={Styles.button} onClick={onChange.bind(this, actual + 1)}>
            {actual+2}
          </button>
          
          {actual< total-2 && <div>...</div>}
        </React.Fragment>
      )
    }
    return (
      <div className={Styles.container}>
        <button className={`${Styles.button} ${Styles.arrow}`} onClick={onChange.bind(this, ((actual - 1) + total) % total )}>&lt;</button>
        {buttons}
        <button className={`${Styles.button} ${Styles.arrow}`} onClick={onChange.bind(this, (actual + 1) % total )}>&gt;</button>
      </div>
    )
  }
}

export default Pagination