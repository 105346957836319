import React, {useState} from "react"
import {navigate, Link} from 'gatsby'
import {login, isLoggedIn} from './auth.js'
import {withFormik} from 'formik'
import {InputField, SubmitButton} from 'components/gravityforms/fields'
import {ReadMoreButton} from 'components/buttons'
const LoginForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit
  } = props
  return (
   
    <form onSubmit={handleSubmit}>
      {errors.form_error &&
       <div className="formfield__error text-red mt-2">{errors.form_error}</div>
      }
      <InputField
        label="Username"
        name="username"
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.username}
        error={errors.username}
        touched={!!touched.username}
        required={true}
      />
      <InputField
        label="Password"
        name="password"
        type="password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        error={errors.password}
        touched={!!touched.password}
        required={true}
      />
      <div className="text-center">
        <SubmitButton className="mt-6 uppercase py-2 px-16 text-center" label="Sign In"/>
        <div className="flex flex-no-wrap align-center justify-center py-5">
          <Link to={props.resetPasswordPath}
                className="test-sm italic font-semibold"
                style={{color:'#000'}}
          >Forgot your password?</Link>
        </div>
        <div className="flex flex-no-wrap align-center justify-center py-5">
          <p className="text-sm tracking-tight " style={{marginTop:2}}>No account yet?</p>
          <ReadMoreButton
            text="Sign Up"
            link={{title: "Sign In", url: props.registerPath}}
            fontColor="black"
            showSymbol={true}
            className="mx-5"
          ></ReadMoreButton>
        </div>
      </div>
    </form>
  )
}

const LoginFormComponent = withFormik({
  mapPropsToValues: () => ({
    username: '',
    password: ''
  }),
  validate: values => {
    const errors = {}
    if(!values.username){
      errors.username = 'Username is Required'
    }
    if(!values.password){
      errors.password = 'Password is Required'
    }
    return errors;
  },
  handleSubmit: async (values,{setSubmitting, setErrors, props}) => {
    try{
      await  login(values.username, values.password)
      setSubmitting(false)
      props.onSubmitSuccess()
    }catch (err){
      setErrors({form_error: err.response.data.non_field_errors[0]})
      setSubmitting(false)
    }
    
  },
  displayName: 'LoginForm'

})(LoginForm);
export default  ({redirectTo, ...rest}) => {
  let [state,setState] = useState();
  if(isLoggedIn()) {
    navigate(`${redirectTo}`, {replace:true})
  }
  return (
    <div className="entry-content" style={{maxWidth:480, margin:"0 auto"}}>
      <h1 className="text-center mb-16">Sign In</h1>
      <div>
        <LoginFormComponent
          redirectPath={redirectTo}
          onSubmitSuccess={()=>setState({})}
          registerPath={rest.registerPath}
          resetPasswordPath={rest.resetPasswordPath}
        />
      </div>
    </div>
  )
}
