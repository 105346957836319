import React from 'react'

import ReactTable from 'react-table-v6'

import axios from 'axios'
import {StyledSelect, StyledAsyncSelect} from '../select'
import {ButtonNoLink} from '../buttons'
import {ReactComponent as ChevronIcon} from 'images/icons/ChevronTable.svg'
import Pagination from './pagination'
import 'react-table-v6/react-table.css'
import './custom.css'
import Styles from './esta.module.css'

const COMMON_API_URI= `${process.env.GATSBY_ESA_API_PROTOCOL}://${process.env.GATSBY_ESA_API_BASEURL}/common/v1`;
const ESTA_API_URI = `${process.env.GATSBY_ESA_API_PROTOCOL}://${process.env.GATSBY_ESA_API_BASEURL}/esta/v1`;

export class Esta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: 1,
      loading: true,
      pageSize: 20,
      speciesChoices: [],
      countriesChoices: [],
      specie: undefined,
      country: undefined,
      company: undefined,
      count: 0
    }
    this.fetchData = this.fetchData.bind(this);
    this.columns= [
      {
        Header: 'Name',
        id: 'issued_to__name',
        accessor: d => d.issued_to.name,
        minWidth: 175
      },
      {
        Header: 'Address',
        id: 'issued_to__address',
        accessor: d => `${d.issued_to.address.street?d.issued_to.address.street:''} ${d.issued_to.address.street_number?d.issued_to.address.street_number:''} ${d.issued_to.address.zip_code?d.issued_to.address.zip_code:''} ${d.issued_to.address.city}`,
        minWidth: 175,
        sortable: false,
      },
      {
        Header: 'Country',
        id: 'issued_to__address__country__name',
        accessor: d => d.issued_to.country.name,
        minWidth: 100
      },


      {
        Header: 'Scope',
        id: 'specie__name',
        accessor: d => d.specie.name,
        minWidth: 175
      },
      {
        Header: 'Certifying body',
        id: 'issued_by__name',
        accessor: d => d.issued_by.name,
        minWidth: 175,
        sortable: false,
      },
      {
        Header: 'First Certification',
        id: 'first-certification',
        sortable: false,
        accessor: d => d.first_cert_date,
        minWidth: 100
      },
      {
        Header: 'Expiration Date',
        id: 'expiration-date',
        sortable: false,
        accessor: d => d.expiration_date,
        minWidth: 100

      },
    /*  {
        Header: 'Company',
        id: 'issued_to__company__name',
        accessor: d=> d.issued_to.company.name,
        minWidth: 175

      },*/
      {
        Header: 'Validity',
        expander: true,
        width: 100,
        Expander: ({ isExpanded, ...rest }) =>
          <div className='flex justify-center'>
            <ChevronIcon style={{transform: isExpanded ? 'rotate(180deg)' : ''}}/>
          </div>,
      },

    ]
    this.childData = (row) => row.history
    this.childColumns = [
      {
        Header: 'From',
        accessor: 'from_date',
        sortable: false
      },
      {
        Header: 'To',
        accessor: 'to_date',
        sortable: false
      },
    ]
  }
  componentDidMount() {
   /* this.setState({loading: true})
    axios.get(`${ESTA_API_URI}/certificates/`, {
      params: {
        limit: 9999,
        offset: 0,
      }
    }).then(({data}) => {
      this.setState({
        data: data.results,
        pages: Math.ceil(data.count/this.state.pageSize),
        loading: false
      })
    })*/
    axios.get(`${ESTA_API_URI}/species/`, {
      params: {
        limit: 999,
      }
    }).then(({data}) => {
      const speciesChoices = data.results.map((specie) => {
        return {value: specie.id, label: specie.name}
      })
      this.setState({speciesChoices})
    })
    axios.get(`${ESTA_API_URI}/certificates/countries/`, {
      params: {
        limit: 999,
      }
    }).then(({data}) => {
      const countriesChoices = data.results.map((country) => {
        return {value: country.id, label: country.name}
      })
      this.setState({countriesChoices})
    })
  }
	fetchData(state, instance) {
    let ordering = ''
    if(state.sorted) {
      if(state.sorted.length > 0) {
        ordering = state.sorted[0].id
        if(state.sorted[0].desc) {
          ordering = '-' +ordering
        }
      }
    }
		this.setState({loading: true})
		state = {...this.state, ...state }
    axios.get(`${ESTA_API_URI}/certificates/`, {
      params: {
        limit: state.pageSize,
        offset: (state.page) * state.pageSize,
        company: this.state.company?this.state.company.value: '',
        //issued_to: this.state.company ? this.state.company.value : '',
        specie: this.state.specie ? this.state.specie.value : '',
        country: this.state.country ? this.state.country.value : '',
        ordering: ordering
      }
    }).then(({data}) => {
      this.setState({
        data: data.results,
        pages: Math.ceil(data.count/state.pageSize),
        loading: false,
        count: data.count
      })
    })

  }
  loadCompanies = (inputValue, callback) => {
    if(inputValue.length > 3) {
      axios.get(`${ESTA_API_URI}/companies/`, {
        params: {
          name__unaccent__icontains: inputValue,
        }
      }).then(({data}) => {
        callback(data.results.map((company) => ({value: company.id, label: company.name})))
      })
    }
  }
  render() {
    return (
      <div>
        <div className={Styles.filtersContainer}>
        <div className={Styles.topContainer}>
          <div className={Styles.filter}>
            <StyledAsyncSelect placeholder='Company' defaultOptions={false} loadOptions={this.loadCompanies} value={this.state.company} onChange={(value) => this.setState({company: value})} isSearchable={true} isClearable={true}/>
          </div>
          <div className={Styles.filter}>
            <StyledSelect placeholder='Species' choices={this.state.speciesChoices} value={this.state.specie} onChange={(value) => this.setState({specie: value})} isSearchable={true} isClearable={true}/>
          </div>
          <div className={Styles.filter}>
            <StyledSelect placeholder='Country' choices={this.state.countriesChoices} value={this.state.country} onChange={(value) => this.setState({country: value})} isSearchable={true} isClearable={true}/>
          </div>
          <div className={Styles.filterButton}>
            <ButtonNoLink onClick={() => this.fetchData({page: 0})}>Filter</ButtonNoLink>
          </div>
        </div>
          {this.state.count > 0 &&
          <div className={Styles.searchCount}>
            <p>Found {this.state.count} items</p>
           </div>
          }
        </div>

        {
          (this.state.loading || (this.state.data && this.state.data.length > 0)) ?
          <div className='table-container'>
            <ReactTable
              data={this.state.data}
              columns={this.columns}
              pages={this.state.pages}
              loading={this.state.loading}
              className='main-table'
              resizable={false}
              minRows={0}
              defaultPageSize={this.state.pageSize}
              onFetchData={this.fetchData}
              showPagination={this.state.pages > 1}
            PaginationComponent={ props => <Pagination {...props} showTotalCount={true} totalCount={this.state.count} />}
              manual
              defaultSorted={[
                {
                  id:"issued_to__name",
                  desc: false
                }

              ]}
              SubComponent={row =>
                <div className='child-table-container'>
                  <ReactTable
                    data={this.childData(row.original)}
                    columns={this.childColumns}
                    className='child-table'
                    resizable={false}
                    minRows={0}
                    showPagination={false}
                    NoDataComponent={() => null}
                  />
                </div>
              }
              NoDataComponent={() => null}
            />
          </div>
          :
          <h1 className='tableNoResults'>No Results</h1>
        }
      </div>
    )
  }
}
