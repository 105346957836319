import React from "react"
import axios from "axios"
import { ButtonNoLink } from "components/buttons"
import { StyledSelect, StyledAsyncSelect } from "components/select"
import ReactTable from "react-table-v6"
import Pagination from "components/tables/pagination"
import { Link } from "@reach/router"
import { ReactComponent as ChevronIcon } from "images/icons/ChevronTable.svg"

import "react-table-v6/react-table.css"
import "components/tables/custom.css"
import Styles from "./pinto.module.css"

const COMMON_API_URI = `${process.env.GATSBY_ESA_API_PROTOCOL}://${process.env.GATSBY_ESA_API_BASEURL}/common/v1`
const PINTO_API_URI = `${process.env.GATSBY_ESA_API_PROTOCOL}://${process.env.GATSBY_ESA_API_BASEURL}/pinto/v1`

export class Pinto extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			pages: 0,
			loading: false,
			pageSize: 20,
			speciesChoices: [],
			speciesLoading: false,
			varietiesChoices: [],
			varitiesLoading: false,
			originalVarietiesChoices: [],
			specie: undefined,
			variety: undefined,
			patentTitle: undefined,
			patentId: undefined,
			count: 0,
			show_export: false,
			export_loading: false,
			availabilityPlatform: undefined,
		}

		this.fetchData = this.fetchData.bind(this)
		this.handleSpecieChange = this.handleSpecieChange.bind(this)
		this.export = this.export.bind(this)
		this.columns = [
			{
				Header: "Species",
				id: "specie__name",
				accessor: "specie.name",
			},
			{
				Header: "Variety denomination",
				accessor: "denomination",
				sortable: false,
			},
			{
				Header: "Patents",
				expander: true,
				width: 100,
				Expander: ({ isExpanded, ...rest }) => (
					<div className="flex justify-center">
						<ChevronIcon
							style={{ transform: isExpanded ? "rotate(180deg)" : "" }}
						/>
					</div>
				),
			},
		]
		this.childData = (row) => row.patents
		this.childColumns = [
			{
				Header: "Patent Title",
				accessor: "title",
				sortable: false,
			},
			{
				Header: "Patent Number",
				id: "patent_link",
				accessor: "patentId",
				Cell: ({ row }) => {
					if (!!row._original.link) {
						let loc = `${window.location.pathname}/patent/${row._original.id}`
						loc = loc.replace("//", "/")
						return (
							<a href={`${loc}`} target="_blank">
								{row._original.patentId}
							</a>
						)
					} else {
						return row._original.patentId
					}
				},
				sortable: false,
			},
			{
				Header: "Patent Holder",
				id: "patent_holder",
				accessor: (d) => d.patent_holder.name,
				sortable: false,
			},
			{
				Header: "",
				id: "availability_platform",
				sortable: false,

				accessor: (d) => {
					console.log("got d", d)
					if (d.availability_platform?.logo) {
						return (
							<a
								href={`${d.availability_platform?.link ?? "#"}`}
								target="_blank"
								className="flex justify-end"
							>
								<img
									src={`${process.env.GATSBY_ESA_API_PROTOCOL}://${process.env.GATSBY_ESA_API_BASEURL}${d.availability_platform.logo}`}
									className="h-6"
								/>
							</a>
						)
					}
				},
			},
		]
	}
	export(state, instance) {
		this.setState({ export_loading: true })
		let ordering = ""
		if (state.sorted && state.sorted.length > 0) {
			ordering = state.sorted[0].id
			if (state.sorted[0].desc) {
				ordering = "-" + ordering
			}
		}
		let patent_id = false
		if (this.state.patentId) {
			patent_id = this.state.patentId.value
		} else if (this.state.patentTitle) {
			patent_id = this.state.patentTitle.value
		}
		axios({
			url: `${PINTO_API_URI}/varieties/export`,
			method: "GET",
			responseType: "blob", // important
			params: {
				specie: this.state.specie ? this.state.specie.value : "",
				id: this.state.variety ? this.state.variety.value : "",
				patent: patent_id ? patent_id : "",
				patent__patent_holder: this.state.patentHolder
					? this.state.patentHolder.value
					: "",
				ordering: ordering,
				patent__availability_platform: state?.availabilityPlatform?.value ?? "",
			},
		}).then((response) => {
			const url = window.URL.createObjectURL(response.data)
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", "pinto_export.xlsx")
			document.body.appendChild(link)
			link.click()
			this.setState({ export_loading: false })
		})
	}
	componentDidMount() {
		this.setState({ loading: true })

		axios
			.get(`${PINTO_API_URI}/species/`, {
				params: {
					limit: 500,
				},
			})
			.then(({ data }) => {
				const speciesChoices = data.results.map((specie) => {
					return { value: specie.id, label: specie.name }
				})
				this.setState({ speciesChoices, speciesLoading: false })
			})
			.catch(() => {
				alert("an unexpected error has occured, please try again later")
			})
	}
	fetchData(state, instance) {
		this.setState({ show_export: false })
		let ordering = ""
		if (state.sorted && state.sorted.length > 0) {
			ordering = state.sorted[0].id
			if (state.sorted[0].desc) {
				ordering = "-" + ordering
			}
		}
		this.setState({ loading: true })
		//handle patent identifiers
		let patent_id = false
		if (this.state.patentId) {
			patent_id = this.state.patentId.value
		} else if (this.state.patentTitle) {
			patent_id = this.state.patentTitle.value
		}
		state = { ...this.state, ...state }
		axios
			.get(`${PINTO_API_URI}/varieties/`, {
				params: {
					limit: state.pageSize,
					offset: state.page * state.pageSize,
					specie: this.state.specie ? this.state.specie.value : "",
					id: this.state.variety ? this.state.variety.value : "",
					patent: patent_id ? patent_id : "",
					patent__patent_holder: this.state.patentHolder
						? this.state.patentHolder.value
						: "",
					ordering: ordering,
					patent__availability_platform:
						state?.availabilityPlatform?.value ?? "",
				},
			})
			.then(({ data }) => {
				this.setState({
					data: data.results,
					pages: Math.ceil(data.count / state.pageSize),
					loading: false,
					count: data.count,
					show_export: data.count > 0,
				})
			})
	}
	loadSpeciesOptions = (inputValue, callback) => {
		let params = {
			ordering: "name",
			limit: 9999,
		}
		if (!!inputValue) {
			params = {
				...params,
				name__icontains: inputValue,
			}
		}
		axios
			.get(`${PINTO_API_URI}/species/`, {
				params,
			})
			.then(({ data }) => {
				callback(
					data.results.map((specie) => ({
						value: specie.id,
						label: specie.name,
					}))
				)
			})
	}
	loadDenominationOptions = (inputValue, callback) => {
		axios
			.get(`${PINTO_API_URI}/varieties/`, {
				params: {
					denomination__istartswith: inputValue,
					specie: this.state.specie ? this.state.specie.value : "",
					patents__publication_status: "PU",
				},
			})
			.then(({ data }) => {
				callback(
					data.results.map((variety) => ({
						value: variety.id,
						label: variety.denomination,
					}))
				)
			})
	}
	loadPatentTitleOptions = (inputValue, callback) => {
		if (inputValue.length > 2) {
			axios
				.get(`${PINTO_API_URI}/patents`, {
					params: {
						title__icontains: inputValue,
					},
				})
				.then(({ data }) => {
					callback(
						data.results.map((patent) => ({
							value: patent.id,
							label: patent.title,
						}))
					)
				})
		}
	}
	loadPatentIdOptions = (inputValue, callback) => {
		if (inputValue.length > 2) {
			axios
				.get(`${PINTO_API_URI}/patents`, {
					params: {
						patentId__icontains: inputValue,
					},
				})
				.then(({ data }) => {
					callback(
						data.results.map((patent) => ({
							value: patent.id,
							label: patent.patentId,
						}))
					)
				})
		}
	}
	loadPatentHolderOptions = (inputValue, callback) => {
		if (inputValue.length > 2) {
			axios
				.get(`${PINTO_API_URI}/holders`, {
					params: {
						name__icontains: inputValue,
					},
				})
				.then(({ data }) => {
					callback(
						data.results.map((patentHolder) => ({
							value: patentHolder.id,
							label: patentHolder.name,
						}))
					)
				})
		}
	}
	loadAvailabilityPlatformOptions = (inputValue, callback) => {
		axios
			.get(`${PINTO_API_URI}/availability_platforms`, {
				params: {
					name__icontains: inputValue,
				},
			})
			.then(({ data }) => {
				callback(
					data.results.map((platform) => ({
						value: platform.id,
						label: platform.name,
					}))
				)
			})
	}

	handleSpecieChange(option) {
		if (option) {
			const specieId = option.value
			const varietiessFiltered = this.state.originalVarietiesChoices.filter(
				(variety) => variety.specie === specieId
			)
			this.setState({
				specie: option,
				variety: "",
				varietiesChoices: varietiessFiltered,
			})
		} else {
			this.setState({
				specie: option,
				varietiesChoices: this.state.originalVarietiesChoices,
			})
		}
	}
	render() {
		return (
			<div>
				<div className={Styles.filtersContainer}>
					<div className={Styles.topContainer}>
						<div className={Styles.filterContainer}>
							<div className={Styles.filterTop}>
								<StyledAsyncSelect
									isSearchable={true}
									isClearable={true}
									isLoading={this.state.speciesLoading}
									loadOptions={this.loadSpeciesOptions}
									defaultOptions={true}
									showChevron={true}
									placeholder="Species"
									value={this.state.specie}
									onChange={this.handleSpecieChange}
								/>
							</div>
							<div className={Styles.filterTop}>
								<StyledAsyncSelect
									isSearchable={true}
									isClearable={true}
									defaultOptions={false}
									isLoading={this.state.varitiesLoading}
									loadOptions={this.loadDenominationOptions}
									placeholder="Variety Denomination"
									cacheOptions
									showChevron={false}
									value={this.state.variety}
									onChange={(value) => this.setState({ variety: value })}
								/>
							</div>
							<div className={Styles.filterBottom}>
								<StyledAsyncSelect
									placeholder="Patent Title"
									loadOptions={this.loadPatentTitleOptions}
									value={this.state.patentTitle}
									onChange={(value) => this.setState({ patentTitle: value })}
									isSearchable={true}
									isClearable={true}
								/>
							</div>
							<div className={Styles.filterBottom}>
								<StyledAsyncSelect
									placeholder="Patent Number"
									loadOptions={this.loadPatentIdOptions}
									value={this.state.patentId}
									onChange={(value) => this.setState({ patentId: value })}
									isSearchable={true}
									isClearable={true}
								/>
							</div>
							<div className={Styles.filterBottom}>
								<StyledAsyncSelect
									placeholder="Patent Holder"
									loadOptions={this.loadPatentHolderOptions}
									value={this.state.patentHolder}
									onChange={(value) => this.setState({ patentHolder: value })}
									isSearchable={true}
									isClearable={true}
								/>
							</div>
							<div className={Styles.filterBottom}>
								<StyledAsyncSelect
									placeholder="Availability Platform"
									loadOptions={this.loadAvailabilityPlatformOptions}
									value={this.state.availabilityPlatform}
									onChange={(value) =>
										this.setState({ availabilityPlatform: value })
									}
									isSearchable={true}
									isClearable={true}
									showChevron={true}
									defaultOptions={true}
								/>
							</div>
						</div>
						<div className={Styles.filterButton}>
							<ButtonNoLink onClick={() => this.fetchData({ page: 0 })}>
								Filter
							</ButtonNoLink>
						</div>
					</div>
				</div>
				{this.state.show_export && (
					<div
						className={`${Styles.exportButton} ${
							this.state.export_loading ? Styles.filterButtonDisabled : ""
						} `}
					>
						<ButtonNoLink onClick={() => this.export({})}>
							{this.state.export_loading
								? "Please wait"
								: "Export Search Results"}
						</ButtonNoLink>
					</div>
				)}
				{this.state.data && this.state.data.length > 0 ? (
					<ReactTable
						data={this.state.data}
						columns={this.columns}
						pages={this.state.pages}
						loading={this.state.loading}
						className="main-table"
						resizable={false}
						minRows={0}
						defaultPageSize={this.state.pageSize}
						onFetchData={this.fetchData}
						defaultSorted={[
							{
								id: "specie__name",
								desc: false,
							},
						]}
						PaginationComponent={(props) => (
							<Pagination
								{...props}
								showTotalCount={true}
								totalCount={this.state.count}
							/>
						)}
						manual
						SubComponent={(row) => (
							<div className="child-table-container">
								<ReactTable
									ref={(instance) => {
										this.table = instance
									}}
									data={this.childData(row.original)}
									columns={this.childColumns}
									className="child-table"
									resizable={false}
									minRows={0}
									showPagination={false}
									NoDataComponent={() => null}
								/>
							</div>
						)}
						NoDataComponent={() => <p className="tableNoResults">No Results</p>}
					/>
				) : (
					<p className="tableNoResults">Use the filters to start a search</p>
				)}
			</div>
		)
	}
}
