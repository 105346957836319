import React, {useEffect} from "react"
import {navigate} from 'gatsby'
import {isLoggedIn, isBrowser} from './auth.js'


const PrivateRoute = ({component:Component, loginLocation, location, ...rest})=> {
    if(!isLoggedIn() && isBrowser && location.pathname !== loginLocation){
      navigate(loginLocation, {redirectTo: location.pathname})
      return null
    }
  

  return <Component {...rest}/>
}

export default PrivateRoute
