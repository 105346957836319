import React, { Component } from 'react'
import classnames from 'classnames'
import Styles from './pagination.module.css'
import {ReactComponent as PetalIcon} from 'images/icons/seeds-point.svg'
import {StyledSelect} from 'components/select'

const defaultButton = props => (
  <button type="button" {...props} className={`${Styles.button} ${Styles.arrow}`}>
    {props.children}
  </button>
)

export default class ReactTablePagination extends Component {
  static defaultProps = {
    PreviousComponent: defaultButton,
    NextComponent: defaultButton,


    renderPageJump: ({page, pages, onPageChange}) => {
      const pagination = (c, m)=> {

          var current = c+1,
              last = m,
              delta = 2,
              left = current - delta,
              right = current + delta + 1,
              range = [],
              rangeWithDots = [],
              l;

          for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
              range.push(i);
            }
          }

          for (let i of range) {
            if (l) {
              if (i - l === 2) {
                rangeWithDots.push(l);
              } else if (i - l !== 1) {
                rangeWithDots.push('...');
              }
            }
            rangeWithDots.push(i-1);
            l = i;
          }

          return rangeWithDots;


      }
      const renderCurrentPageButton = (page)=>(
			  <button className={`${Styles.button} ${Styles.active}`} key="current">
          {page+1}
          <div className={Styles.petal}>
            <PetalIcon />
          </div>

        </button>
      )
      const renderChangePageButton = (page)=>(
			  <button className={Styles.button} onClick={()=>onPageChange(page)} key={page}>
          {page+1}
        </button>
      )
		const renderGap = (idx) => (<span className={Styles.gap} key={idx}>...</span>)
		const elems = pagination(page, pages).map((e, idx )=>{
        if(e === '...'){
          return renderGap(idx)
        }else if (e===page){
          return renderCurrentPageButton(e)
        }else {
          return renderChangePageButton(e)
        }
      });
      return (
        <div className={Styles.pageContainer}>
          {elems.map(e => e)}
        </div>
      )
    },
    renderTotalPagesCount: pages => <span className="-totalPages">{pages || 1}</span>,
    renderPageSizeOptions: ({
      pageSize,
      pageSizeOptions,
      rowsSelectorText,
      onPageSizeChange,
      rowsText,

    }) => {
      const options = pageSizeOptions.map((option, i) => {
        return {
          value: option,
          label: `${option} ${rowsText}`
        }
      })
      return(
        <div className="flex">
        <span>{rowsSelectorText}</span>

      <StyledSelect
        value={options.filter(option=>option.value === pageSize)}
        choices={options}
        onChange = {(option)=>onPageSizeChange(Number(option.value))}
        isClearable = {false}
        defaultMenuIsOpen = {false}

          />

        </div>
      )
    },
  }

  constructor (props) {
    super(props)
    this.getSafePage = this.getSafePage.bind(this)
    this.changePage = this.changePage.bind(this)
    this.applyPage = this.applyPage.bind(this)

    this.state = {
      page: props.page,
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.page !== this.props.page && prevState.page !== this.state.page) {
      // this is probably safe because we only update when old/new state.page are different
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        page: this.props.page,
      })
    }
  }

  getSafePage (page) {
    if (Number.isNaN(page)) {
      page = this.props.page
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1)
  }

  changePage (page) {
    page = this.getSafePage(page)
    this.setState({ page })
    if (this.props.page !== page) {
      this.props.onPageChange(page)
    }
  }

  applyPage (e) {
    if (e) {
      e.preventDefault()
    }
    const page = this.state.page
    this.changePage(page === '' ? this.props.page : page)
  }

  getPageJumpProperties () {
    return {
      onKeyPress: e => {
        if (e.which === 13 || e.keyCode === 13) {
          this.applyPage()
        }
      },
      onBlur: this.applyPage,
      value: this.state.page === '' ? '' : this.state.page + 1,
      onChange: e => {
        const val = e.target.value
        const page = val - 1
        if (val === '') {
          return this.setState({ page: val })
        }
        this.setState({ page: this.getSafePage(page) })
      },
      inputType: this.state.page === '' ? 'text' : 'number',
      pageJumpText: this.props.pageJumpText,
    }
  }

  render () {
    const {
      // Computed
      pages,
      // Props
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
      PreviousComponent,
      NextComponent,
      renderPageJump,
      renderCurrentPage,
      renderTotalPagesCount,
      renderPageSizeOptions,
      showTotalCount,
      totalCount
    } = this.props

    return (
      <div className={Styles.container}>

          <PreviousComponent
            onClick={() => {
              if (!canPrevious) return
              this.changePage(page - 1)
            }}
            disabled={!canPrevious}
          >
            &lt;
          </PreviousComponent>

        {showPageJump && renderPageJump({page, pages, onPageChange:this.changePage}) }
          <NextComponent
            onClick={() => {
              if (!canNext) return
              this.changePage(page + 1)
            }}
            disabled={!canNext}
          >
            &gt;
          </NextComponent>

        <div>
          {showPageSizeOptions &&
           renderPageSizeOptions({
             pageSize,
             rowsSelectorText: this.props.rowsSelectorText,
             pageSizeOptions,
             onPageSizeChange,
             rowsText: this.props.rowsText,
           })}
        </div>
      {showTotalCount &&
       <div className={Styles.totalcount}>
         {totalCount} Records
       </div>
      }
      </div>
    )
  }
}
