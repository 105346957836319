import React from 'react'

import ReactTable from 'react-table-v6'
import Pagination from 'components/Pagination'
import axios from 'axios'
import {StyledSelect, StyledAsyncSelect} from '../select'
import {ButtonNoLink} from '../buttons'
import {ReactComponent as ChevronIcon} from 'images/icons/Chevron.svg'

import 'react-table-v6/react-table.css'
import './custom.css'
import Styles from './esta.module.css'

class HCProvider {

  static HC_API_URI = `${process.env.GATSBY_ESA_API_PROTOCOL}://${process.env.GATSBY_ESA_API_BASEURL}/esta/v1/heubachcompanies/`

  static heubachCompanies(pageSize, page, sorted, filtered) {
    
    let params = {
      limit: pageSize,
      offset: page > 0?pageSize*page:0,
      ordering: sorted
    }
    return axios.get(this.HC_API_URI, {params})
  }
}

export class Heubach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: -1,
      pageSize: 999,
      loading: false
    }
    this.fetchData = this.fetchData.bind(this);
    this.columns= [
      {
        Header: 'Company',
        id: 'name',
        accessor: d => d.name
      },
      {
        Header: 'Town',
        id: 'town',
        accessor: d => d.town,
        sortable: false
      },
      {
        Header: 'Country',
        id: 'country__name',
        accessor: d => d.country.name
      },
    ]
  }
  
  fetchData({page, pageSize, sorted, filtered}){
    if(!!sorted && sorted.length > 0){
      sorted = `${sorted[0].desc?'-':''}${sorted[0].id}`
    }
    
    this.setState({loading:true})
    HCProvider.heubachCompanies(
      pageSize,
      page,
      sorted,
      filtered
    ).then(({data})=>{
      this.setState({
        data: data.results,
        pages: Math.ceil(data.count/pageSize),
        loading:false
      })
    })
      .catch(({data})=>{
        this.setState({
          loading:false,
          pages: 0,
          data:[]
        })
      })
  }
  render() {
    return (
      <div>
        <ReactTable 
          data={this.state.data}
          columns={this.columns}
          pages={this.state.pages}
          loading={this.state.loading}
          onFetchData={this.fetchData}
          className='main-table' 
          resizable={false}
          minRows={0}
          defaultPageSize={this.state.pageSize}
          PaginationComponent={props => <Pagination {...props}/>}
          showPagination={false}
          manual
          NoDataComponent={() => null}
       />
      </div>
    )
  }
}
