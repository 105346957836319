import React, { createContext, useState, useEffect, useCallback } from "react"
import axios from "axios"
const HRT_API_URI = `${process.env.GATSBY_ESA_API_PROTOCOL}://${process.env.GATSBY_ESA_API_BASEURL}/hrt/v1`

async function getCrops() {
	const results = await axios.get(`${HRT_API_URI}/crops`, {
		params: {
			limit: 999,
		},
	})
	return (
		results?.data?.results
			?.filter(crop => crop.crop_name && crop.crop_name != "")
			?.map(crop => {
				return {
					id: crop.id,
					scientificName: crop.name,
					commonName: crop.crop_name,
					pests: crop.pests,
				}
			}) ?? []
	)
}
async function getResistanceLevels() {
	const results = await axios.get(`${HRT_API_URI}/hrtlevels`, {
		params: {
			limit: 999,
		},
	})
	return results?.data ?? []
}
async function getPestTypes() {
	const results = await axios.get(`${HRT_API_URI}/pesttypes`, {
		params: {
			limit: 999,
		},
	})
	return results?.data?.results?.map(type => {
		return {
			id: type.id,
			name: type.name,
		}
	})
}

function processSpeciesList(species) {
	let codes = []
	let names = []
	let results = species.map(specie => {
		//process species codes
		if (specie.code) {
			let codeObject = codes.find(n => n.code === specie.code)
			if (!codeObject) {
				codeObject = { code: specie.code, pestSpecieIds: [] }
			}
			codeObject.pestSpecieIds.push(specie.id)
			codes = [...codes.filter(n => n.code !== specie.code), codeObject]
		}

		//process species names
		specie.commonNames?.forEach(({ name }) => {
			let nameObject = names.find(n => n.name === name)
			if (!nameObject) {
				nameObject = { name: name, pestSpecieIds: [] }
			}
			nameObject.pestSpecieIds.push(specie.id)
			names = [...names.filter(n => n.name !== name), nameObject]
		})
		return {
			id: specie.id,
			name: specie.name,
			commonNames: specie.commonNames?.map(n => ({ name: n.name })) ?? [],
			code: specie.code,
			codeNote: specie.code_note,
			typeId: specie?.pestType?.id ?? specie?.typeId ?? null, // processSpeciesList can receive data from the remote api or from hte HRTcontroller itself, the schema between the two varies slightly. didn't have time to tidy it  up properly
		}
	})
	const list = {
		pests: results,
		pestsCodes: codes,
		pestsCommonNames: names,
	}
	return list
}
async function getPestSpecies() {
	const results = await axios.get(`${HRT_API_URI}/pests`, {
		params: {
			limit: 999,
		},
	})
	return processSpeciesList(
		results?.data?.results.map(r => ({
			...r,
			commonNames: [...r.common_names],
		}))
	)
}

const defaultQuery = {
	crop: null,
	exampleSearch: null,
	pestSpecie: null,
	pestType: [],
	pestCommonName: null,
	pestCode: null,
	resistanceLvl: [],
}

export const HRTSearchContext = createContext({
	initialOptions: {},
	options: {},
	query: defaultQuery,
	loading: false,
	results: [],
	totalCount: 0,
	/*	search: () => {},
	reset: () => {},
	updateQuery: () => {},*/
})
function computeCropsOptions(allOptions, query) {
	// crops options depends on
	//  - pestSpecie
	//  - pestType

	let validCrops = allOptions.crops

	//if specie is set in the query, we can ignore the rest of the filters since a specie can only belong to one type
	if (query.pestSpecie) {
		validCrops = validCrops.filter(
			crop => crop.pests?.map(p => p.id)?.includes(query.pestSpecie) ?? false
		)
	} else if (query.pestCommonName || query.pestCode) {
		// at this point, allOptions.pests is already filtered based on crop, so if we do not make sure that the query actually includes something that constrains the valid crops, the returned crop set will only include crops that have a relation to the subset of pests.
		// to illustrate, if we only select a crop, and do not check the other query parameters, we would only have the selected crop as an option since the pests list had already been filtered upstream of this function
		const pestIds = allOptions.pests.map(p => p.id)
		//prefilter the crops based on the species
		validCrops = validCrops.filter(crop =>
			crop.pests.find(p => pestIds.includes(p.id))
		)

		if (query.pestType && query.pestType.length > 0) {
			//get ids of crops that match the pest type
			const cropIds = []
			query.pestType.forEach(pestType => {
				validCrops.forEach(crop => {
					crop.pests.forEach(pest => {
						if (query.pestType.includes(pest.pestType.id)) {
							cropIds.push(crop.id)
						}
					})
				})
			})
			validCrops = validCrops.filter(crop => cropIds.includes(crop.id))
		}
	}
	return validCrops
}

function computePestTypesOptions(allOptions, query) {
	// pest types options depends on
	//   - pest specie (one type per pest specie)
	//   - crops

	let validTypes = allOptions.pestTypes.filter(type =>
		allOptions.pests.find(p => p.typeId === type.id)
	)
	if (query.pestSpecie) {
		//there can only be one type if a pest specie is selected
		const pest = allOptions.pests.find(pest => pest.id === query.pestSpecie)
		return validTypes.filter(type => pest.typeId === type.id)
	} else {
		if (query.crop) {
			const crop = allOptions.crops.find(crop => crop.id === query.crop)
			let typesIds = new Set()
			crop.pests.forEach(p => {
				if (!typesIds.has(p.pestType.id)) {
					typesIds.add(p.pestType.id)
				}
			})
			validTypes = validTypes.filter(t => typesIds.has(t.id))
		}
	}

	return validTypes
}

function computePestSpeciesOptions(allOptions, query) {
	// pest species options depends on:
	//   - crop
	//   - pest type
	//   - pestCommonNames
	//   - pestCodes (one code per pest specie)

	let validPests = allOptions.pests

	//handle crop
	if (query.crop) {
		const crop = allOptions.crops.find(crop => crop.id === query.crop)
		validPests = validPests.filter(pest =>
			crop.pests.map(p => p.id).includes(pest.id)
		)
	}
	if (query.pestCommonName) {
		validPests = validPests.filter(pest =>
			pest.commonNames?.map(n => n.name).includes(query.pestCommonName)
		)
	}
	if (query.pestCode) {
		validPests = validPests.filter(pest => pest.code === query.pestCode.value)
	}
	if (query.pestType && query.pestType.length) {
		validPests = validPests.filter(pest => query.pestType.includes(pest.typeId))
	}

	return validPests
}

function computePestCodesOptions(allOptions, query) {
	// pest codes options depends on:
	//  - pest specie (one code per pest specie)
	//  - anything that might change the pest specie options (defer to computePestSpecieOptions)
	const validCodes = allOptions.pestsCodes
	if (query.pestSpecie) {
		return validCodes.filter(code =>
			code.pestSpecieIds.includes(query.pestSpecie)
		)
	}
	return validCodes
}
function computePestCommonNamesOptions(allOptions, query) {
	// pest common name options depends on:
	//  - pest specie
	//  - anything that might change the pest specie options (defer to computePestSpecieOptions)
	const validNames = allOptions.pestsCommonNames
	if (query.pestSpecie) {
		return validNames.filter(name =>
			name.pestSpecieIds.includes(query.pestSpecie)
		)
	}
	return validNames
}

// regenerates the full options set based on the current query
// each type of option is handled separately

function computeOptions(initialOptions, query) {
	//we process pests separately to regenerate the initial list of pestCodes and pestsCommonName
	// this save some codes and allow us to make sure we follow the format outputed by processSpecies List
	const { pests, pestsCodes, pestsCommonNames } = processSpeciesList(
		computePestSpeciesOptions(initialOptions, query)
	)
	let options = {
		crops: computeCropsOptions({ ...initialOptions, pests }, query),
		pestTypes: computePestTypesOptions({ ...initialOptions, pests }, query),
		pests, //already processed above
		pestsCodes: computePestCodesOptions(
			{ ...initialOptions, pestsCodes }, //we override the available options for postCodes based on the processed pests lists
			query
		),
		pestsCommonNames: computePestCommonNamesOptions(
			{ ...initialOptions, pestsCommonNames }, //same as pestsCodes
			query
		),
		resistanceLvls: initialOptions.resistanceLvls, // lvls are set at the hrt levels, so we won't be messing with that
	}

	return options
}

const defaultOptions = {
	crops: [],
	pestTypes: [],
	pests: [],
	pestCodes: [],
	pestCommonNames: [],
	resistanceLvls: [],
}

async function fetchData(query, params) {
	const { data } = await axios.get(`${HRT_API_URI}/hrt/`, {
		params,
	})
	return {
		data: data.results,
		pages: Math.ceil(data.count / params.limit),
		count: data.count,
	}
}

export const HRTController = ({ children }) => {
	const [sortOptions, setSortOptions] = useState(null)
	const [query, setQuery] = useState(defaultQuery)
	const [loading, setLoading] = useState(true)
	const [initialOptions, setInitialOptions] = useState(defaultOptions)
	const [options, setOptions] = useState(null)
	const [results, setResults] = useState(null)
	const [data, setData] = useState({
		data: [],
		pages: 0,
		count: 0,
	})
	const [tableState, setTableState] = useState({
		pageSize: 20,
		page: 0,
	})

	// update query only take a single dimension at the time in order to be able to filter out non mathcing element in the other dimensiosn
	const updateQuery = ({ key, value }) => {
		let nq = { ...query }
		nq[key] = value

		setQuery(nq)
	}

	const getPestSpeciesFromQuery = query => {
		//pestSpecie is explicitely selected, abort early
		if (query.pestSpecie) {
			return [query.pestSpecie]
		}
		let species = []
		let speciesByName = []
		let speciesByCode = []

		if (query.pestCommonName) {
			// pestCommonName can point to several species
			speciesByName =
				options.pestsCommonNames.find(o => o.name === query.pestCommonName)
					?.pestSpecieIds ?? []
		}

		if (query.pestCode) {
			// pestCode can point to several species
			speciesByCode =
				options.pestsCodes.find(c => c.code === query.pestCode.value)
					?.pestSpecieIds ?? []
		}
		// if both are set, return the intersection
		if (speciesByName.length > 0 && speciesByCode.length > 0) {
			species = speciesByName.filter(id => speciesByCode.includes(id))
		} else {
			//one or both of the array are empty, we can return the concat of the two arrays
			species = [...speciesByName, ...speciesByCode]
		}

		return species
	}
	const search = async (_state, instance) => {
		setLoading(true)
		let ordering = ""
		let state = { ...tableState, ..._state }
		if (state.sorted) {
			if (state.sorted.length > 0) {
				ordering = state.sorted[0].id
				if (state.sorted[0].desc) {
					ordering = "-" + ordering
				}
			}
		}

		const params = {
			limit: state.pageSize,
			offset: state.page * state.pageSize,
			ordering,
			level__in: query?.resistanceLvl?.join(",") ?? undefined,
			crop: query.crop ?? undefined,
			pest_specie__pestType__in: query.pestType.join(",") ?? undefined,
			pest_specie__in: getPestSpeciesFromQuery(query)?.join(",") ?? undefined,
		}
		if (query.exampleSearch) {
			params["examples__icontains"] = query.exampleSearch
		}
		let results = await fetchData(query, params)
		setTableState(state)
		setData(results)
		setLoading(false)
	}

	const reset = () => {
		setResults([])
		setOptions({ ...initialOptions })
	}
	//load all options
	useEffect(() => {
		setLoading(true)
		const fetchData = async () => {
			const { pests, pestsCodes, pestsCommonNames } = await getPestSpecies()
			const options = {
				crops: await getCrops(),
				pestTypes: await getPestTypes(),
				pests,
				pestsCodes,
				pestsCommonNames,
				resistanceLvls: await getResistanceLevels(),
			}

			return options
		}

		fetchData().then(options => {
			setInitialOptions(options)
			setOptions(options)
			setLoading(false)
		})
	}, [setOptions, setLoading])

	useEffect(() => {
		if (query) {
			setOptions(computeOptions(initialOptions, query))
		}
	}, [initialOptions, query])

	return (
		<HRTSearchContext.Provider
			value={{
				initialOptions: options,
				options,
				query: query,
				results,
				loading,
				updateQuery,
				reset,
				search,
				data,
				tableState,
			}}
		>
			{children}
		</HRTSearchContext.Provider>
	)
}
