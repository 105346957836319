import React, {useState, useEffect} from "react"
import axios from 'axios'
import Pagination from 'components/tables/pagination'

import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'
class PatentProvider {

  static PATENTS_API_URI = `${process.env.GATSBY_ESA_API_PROTOCOL}://${process.env.GATSBY_ESA_API_BASEURL}/pinto/v1/patents`
  static Patent(id) {
    return axios.get(`${this.PATENTS_API_URI}/${id}`)
  }
}

const columns=[
  {
    Header: 'Specie',
    id: "specie_name",
    accessor: d => d.specie.name,
    sortable: true
  },
  {
    Header: 'Variety',
    id: 'variety_denomination',
    accessor: d => d.denomination,
    sortable: true
  },
]

export const PatentDetail = (props) => {

  const [loading, setLoading] = useState(true)
  const [patent, setPatent] = useState({})
  const [pageSize, setPageSize] = useState(20)
  useEffect( ()=>{
    const fetchData = async() => {

      const result = await PatentProvider.Patent(props.patentId)
      setPatent(result.data)
      setLoading(false)
    }
    fetchData()
  }, [])

  return(

    <div className="entry-content container">
      {!loading &&
       <>
         <div className="mb-8">
           <p>Patent: <a href={patent.link} target='_blank' rel="noreferrer noopener">{patent.patentId}</a></p>
           <h2>{patent.title}</h2>
           <p>Patent holder: {patent.patent_holder.name}</p>
         </div>
         <div>
           <ReactTable
             data={patent.varieties}
             defaultPageSize={pageSize}
             className="main-table"
             columns={columns}
             rezisable={false}
             minRows={0}
             PaginationComponent = {props => <Pagination {...props} totalCount={patent.varieties.length} showTotalCount={true}/>}
             
           />

           
         </div>
       </>
      }

      
    </div>
  )
}
