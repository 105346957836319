import React, {useState, useEffect} from "react"
import {Router, Location, Link} from "@reach/router"
import {Pinto as PintoTable} from "./tables/pinto"
import {PatentDetail} from './patent'
import PrivateRoute from './auth/PrivateRoute'
import Login from './auth/login'
import Register from './auth/register'
import ResetPassword from './auth/resetpassword'
import ConfirmPassword from './auth/confirmPassword'
import {isBrowser} from './auth/auth.js'


const Routes = () => {

  const base_location = process.env.GATSBY_PINTO_BASE_LOCATION.slice(0, -1)
  return (
  <Location>
      {({location}) => (
        <Router location={location} basepath={`${base_location}`}>
          <Login path={`/login`}
                 redirectTo={base_location}
                 registerPath={`${base_location}/register`}
                 resetPasswordPath={`${base_location}/password/reset`}
          />
          <Register path={`/register`} redirectTo={base_location} loginPath={`${base_location}/login`}/>
          <ResetPassword
            path={`/password/reset`}
            redirectTo={`${base_location}/password/reset/confirm`}
            loginPath={`${base_location}/login`}
          />
           
          <ConfirmPassword
            path={`/password/reset/confirm/:uid/:token`}
            resetPath={`${base_location}/password/reset`}
            redirectTo={base_location}
          />
          <PrivateRoute loginLocation={`${base_location}/login`} component={PatentDetail} path={`/patent/:patentId`}/>
          <PrivateRoute loginLocation={`${base_location}/login`} path="/" component={PintoTable}></PrivateRoute>
        </Router>
      )}
  </Location>)
}
export const Pinto = () => {
  const [isClient, setClient] = useState(false);
  useEffect(() => {
    setClient(true);
  }, []);

  let content;
  if(!isClient){
    content = null;
  }else{
    content = <Routes/>
  }
  return (
    <>
    {content}
    </>
  )
}


