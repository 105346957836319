import React, {useState} from "react"
import {navigate, Link} from 'gatsby'
import {changePassword} from './auth.js'
import {withFormik} from 'formik'
import {InputField, SubmitButton} from 'components/gravityforms/fields'
import {ReadMoreButton} from 'components/buttons'

const ChangePasswordForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit
  } = props
  return (
   
    <form onSubmit={handleSubmit}>
      {errors.form_error &&
       <div className="formfield__error text-red mt-2 mb-2" dangerouslySetInnerHTML={{__html:errors.form_error}}/>
      }
      <InputField
        label="New password"
        name="password1"
        type="password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password1}
        error={errors.password1}
        touched={!!touched.password1}
        isRequired={true}
      />
      <InputField
        label="Confirm the password"
        name="password2"
        type="password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password2}
        error={errors.password2}
        touched={!!touched.password2}
        isRequired={true}
      />


      <div className="text-center">
        <SubmitButton className="mt-6 uppercase py-2 px-16 text-center" label="Submit"/>
      </div>
    </form>
  )
}

const ChangePasswordFormComponent = withFormik({
  mapPropsToValues: () => ({
    password1: '',
    password2: ''
  }),
  validate: values => {
    const errors = {}
    if(!values.password1){
      errors.password1 = 'Password is Required'
    }
    if(!values.password2){
      errors.password2 = 'Password confirmation is required'
    }
    else if (values.password1 !== values.password2){
      errors.password1 = "Password and password confirmation doesn't match"
      errors.password2 = "Password and password confirmation doesn't match"
    }

    return errors;
  },
  handleSubmit: async (values,{setSubmitting, setErrors, props}) => {
    try{
      await  changePassword(values.password1, values.password2, props.uid, props.token )
      setSubmitting(false)
      props.onSubmitSuccess()
    }catch (err){
      if(!!err.response.data){
        if(!!err.response.data.token || !!err.response.data.uid){
          setErrors({form_error: `<p>The link is no longer valid, please restart the reset password  process by <a href="${props.resetPath}" style="color:#000;text-decoration:underline">clicking here</a> or login <a href="${props.redirectPath}" style="color:#000;text-decoration:underline">here</a></p>`})
        }
        else {

          const error_msg = Object.values(err.response.data).map(e=>e.join('')).join('<br/>')
          setErrors({form_error: error_msg})
        }
      }else {
        console.err(err)
        setErrors({form_error: 'something unexpected happends'})
      }
      setSubmitting(false)
    }
    
  },
  displayName: 'LoginForm'

})(ChangePasswordForm);
export default  ({redirectTo, ...rest}) => {
  let [state,setState] = useState(false);
  return (
    <div className="entry-content" >
      <h1 className="text-center mb-16">Change Your Password</h1>
      <div style={{maxWidth:480, margin:"0 auto"}}>

    <>
      {!state &&
       <>
       <div className="mb-8">
         <p>Use the form below to reset your password</p>
       </div>
        <ChangePasswordFormComponent
          redirectPath={redirectTo}
          onSubmitSuccess={()=>{setState(true)}}
          resetPath={rest.resetPath}
          uid={rest.uid}
          token={rest.token}
       />
       </>
        }
    {state &&
     <div className="text-center">
     <p>Your password has been updated successfully</p>
     <p>
       <Link to={redirectTo}>click here </Link>to sign in with your new credentials
     </p>
     </div>
    }
    </>
      </div>
    </div>
  )
}
