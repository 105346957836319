import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout'


import {BannerDoubleCard} from 'components/databases/banners/bannerDoubleCard'
import {BackButton} from 'components/databases/backButton'
import { Esta, Heubach, CertifyingBodies, HrtTable } from '../components/tables';
import {Pinto} from '../components/pinto'
import PageBanner from '../components/page-banner'
import Styles from './database-pages.module.css'


export const DatabasePageTemplate = ({data}) => (
  <Layout
    articleId={`${data.wordpressPage.type}-${data.wordpressPage.wordpress_id}`}
    articleClassName={`${data.wordpressPage.classes} datapage-tpl` }>
    <PageBanner props={data.wordpressPage.pageBanner}/>
    {data.wordpressPage.includedDatabase !== 'pinto' &&
     <div className="entry-content" style={{maxWidth: 640, margin: 'auto', padding: 30}} dangerouslySetInnerHTML={{__html:data.wordpressPage.content}}/>
    }
    <div className={Styles.container}>
    <div className={Styles.databaseContainer}>
        {data.wordpressPage.includedDatabase === 'esta' &&
          <div className={Styles.database} >
            <Esta />
          </div>
        }
        {data.wordpressPage.includedDatabase === 'pinto' &&
         <div className={Styles.database} >
		 <div className="entry-content" style={{margin:'25px 25px'}} dangerouslySetInnerHTML={{__html:data.wordpressPage.content}}/>
            <Pinto />
          </div>
        }
        {data.wordpressPage.includedDatabase === 'heubach' &&
          <div className={Styles.database} style={{width: '100%'}} >
            <Heubach />
          </div>
        }
        {data.wordpressPage.includedDatabase === 'certifyingbodies' &&
         <div className={Styles.database}>
           <CertifyingBodies/>
         </div>
        }
        {data.wordpressPage.includedDatabase === 'hrt' &&
         <div className={Styles.database}>
             <HrtTable/>
         </div>
        }
      </div>
    </div>
  </Layout>
)

export default DatabasePageTemplate
export const DatabasePageQuery = graphql `
query DatabasePageById($id: String!){
  wordpressPage(id:{eq:$id}){
    includedDatabase
    title
    content
    path
    type
    date
    wordpress_id
    classes
    pageBanner {
     ...PageBanner
    }
  }
}
`
